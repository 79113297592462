import React, { useContext, useEffect, useState } from "react";
import { Logo, NavLinksData, FooterBottomData } from "@/data";
import { Link } from "gatsby";
import { MenuContext } from "@/context/menu-context";
import { propTypes } from "react-bootstrap/esm/Image";
import { StaticImage } from "gatsby-plugin-image"


const HeaderOne = (props) => {
    const [sticky, setSticky] = useState(false);
    const { menuStatus, updateMenuStatus } = useContext(MenuContext);
    const handleScroll = () => {
        if (window.scrollY > 70) {
            setSticky(true);
        } else if (window.scrollY < 70) {
            setSticky(false);
        }
    };
    const handleMenuClick = e => {
        e.preventDefault();
        updateMenuStatus(!menuStatus);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [sticky]);

    return (
        <header className="header home-page-one">
            <nav
                className={`navbar navbar-default header-navigation  ${true === sticky
                    ? " stricky stricky-fixed slideInDown animated"
                    : " stricky slideIn animated"
                    }`}
            >
                <div className="thm-container clearfix">
                    <div className="navbar-header">
                        <Link to="/" className="navbar-brand">
                            <StaticImage
                                src="../images/logo.png"
                                alt="TOPaper logo"
                                placeholder="none"
                                quality={100}
                                layout="fixed"
                                width={200}
                                height={47}
                            />
                        </Link>
                        <span className="mobile-menu__toggler" onClick={handleMenuClick}>
                            <i className="fa fa-bars"></i>
                        </span>
                    </div>

                    <div
                        className="collapse show navbar-collapse main-navigation mainmenu "
                        id="main-nav-bar"
                    >
                        <ul className="nav navbar-nav navigation-box">
                            {NavLinksData.map((links, index) => {
                                return (
                                    <li key={index}>
                                        { links.url === "#"
                                            ?
                                            <a href={"#"}>{links.name}</a>

                                            :
                                            <Link to={links.url}>{links.name}</Link>
                                        }
                                        {undefined !== links.subItems ? (
                                            <ul className="sub-menu">


                                                {/* {links.subItems.map((subLinks, index) => (
                                                    <li key={index}>
                                                        <Link to={subLinks.url}>{subLinks.name}</Link>
                                                    </li>
                                                ))} */}


                                                {props.serving.edges.map((cat, index) => {
                                                    if (cat.node.children.edges && cat.node.children.edges.length > 0) {
                                                        return (
                                                            <li key={index}>
                                                                <Link to={`/${cat.node.slug}/`}>{cat.node.name}</Link>
                                                            </li>
                                                        )
                                                    }
                                                })}


                                            </ul>
                                        ) : null}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="right-side-box">
                        <div className="social">
                            {FooterBottomData.social.map(({ icon, url, text }, index) => {
                                return (
                                    <a key={index} href={url} rel="noreferrer" target="_blank" className={`${icon} hvr-pulse`}>
                                        <span className="visually-hidden">{text}</span>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default HeaderOne;
