import React from "react";
import { GatsbyImage } from "gatsby-plugin-image"

const ProgressOne = (props) => {
    let data = props.data;
    return (
        <section className="service-style-two about-page">
            <div className="overlay"></div>
            <div className="background-right-kd">
                <GatsbyImage
                    alt={data.image.altText}
                    loading={"lazy"}
                    image={data.image.imageFile.childImageSharp.gatsbyImageData}
                    formats={["auto", "webp", "avif"]}
                />
            </div>
            <div className="thm-container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="service-content">
                            <span>{data.subtitle}</span>
                            <h2>{data.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: data.content }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProgressOne;
