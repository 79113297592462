import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOne from "@/components/header-one";
import ProgressOne from "@/components/progress-one";
import MenuContextProvider from "@/context/menu-context";
import FeatureOne from "@/components/featured-one";
import PageHeader from "@/components/page-header";


import { graphql } from "gatsby"


const AboutUsTemplate = (props) => {
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://topaper.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://topaper.gr${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }
    return (
        <MenuContextProvider>
            <Layout serving={data.categories} metaData={constructMetaData(data.page, props.pageContext.currentPage)}>
                <HeaderOne serving={data.categories} />
                <PageHeader title={"Λίγα λόγια για εμάς..."} name={"Λίγα λόγια για εμάς..."} />
                <div className="thm-container sec-pad">
                    <div className="sec-title text-center">
                        <span>{data.page.aboutPageAcf.whoWeAre.subtitle}</span>
                        <h3>{data.page.aboutPageAcf.whoWeAre.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: data.page.aboutPageAcf.whoWeAre.content }} />
                    </div>
                </div>
                <ProgressOne data={data.page.aboutPageAcf.ourHistory} />
                <FeatureOne data={data.page.aboutPageAcf.whyChooseUs} />
                <Footer />
            </Layout>
        </MenuContextProvider>
    );
};

export default AboutUsTemplate;


export const pageQuery = graphql`query GET_ABOUT_US($id: ID!) {
    wpgraphql {
        page(id: $id) {
            seo {
                canonical
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                    sourceUrl
                }
            }

            aboutPageAcf{
                whoWeAre{
                    title
                    subtitle
                    content
                }
                ourHistory{
                    title
                    subtitle
                    content
                    image{
                        sourceUrl
                        altText
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED, width:570)
                            }
                        }
                    }
                }
                whyChooseUs{
                    title
                    subtitle
                    content
                    benefits{
                        title
                        content
                        image{
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED, width:570)
                                }
                            }
                        }
                    }
                }
            }
            
        }

        categories (first:500) {
            edges {
              node {
                name
                description
                slug
                categoryPageH1{
                  categoryImage{
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                    }
                  }
                }
                children {
                  edges {
                    node {
                      name
                      posts {
                        edges {
                          node {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
  
    }
}
`