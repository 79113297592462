import React, { Fragment } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import {
    Logo,
    FooterAboutData,
    FooterLinksData,
    FooterPostsData,
    FooterContactData,
    FooterBottomData,
} from "@/data";

const Footer = () => {
    return (
        <Fragment>
            <footer className="footer">
                <div className="thm-container">
                    <div className="flex flex-wrap">
                        <div className=" w-full lg:w-2/5">
                            <div className="footer-widget about-widget">
                                <div className="title">
                                    <Link to="/" className="navbar-brand">
                                        <StaticImage
                                            src="../images/logo.png"
                                            alt="TOPaper logo"
                                            placeholder="none"
                                            quality={100}
                                            layout="fixed"
                                            width={200}
                                            height={47}
                                        />                                    
                                    </Link>
                                </div>
                                <p>Η εταιρεία TOPaper ξεκίνησε εν έτη 1990 και μέχρι σήμερα διανύει 31 χρόνια ανοδικής πορείας στην επεξεργασία χαρτιού περιτυλίγματος, χαρτιού υγείας, τραπεζομάντηλου-σουπλά και χαρτοπετσέτας.</p>

                                {/* <form action="#" className="footer-subscribe">
                                    <input type="text" name="email" placeholder="Email address" />
                                    <button type="submit">
                                        <i className="fa fa-angle-right"></i>
                                    </button>
                                </form> */}
                            </div>
                        </div>
                        <div className=" w-full lg:w-1/5">
                            <div className="footer-widget links-widget explore">
                                <div className="title">
                                    <h3>{FooterLinksData.title}</h3>
                                </div>
                                <ul className="link-list">
                                    {FooterLinksData.links.map(({ url, label }, index) => {
                                        return (
                                            <li key={index}>
                                                <Link to={url}>{label}</Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className=" w-full lg:w-1/5">
                            <div className="footer-widget post-widget services">
                                <div className="title">
                                    <h3>{FooterPostsData.title}</h3>
                                </div>
                                <div className="post-widget">
                                    {FooterPostsData.posts.map(({ date, title, url }, index) => {
                                        return (
                                            <div className="single-post-widget" key={index}>
                                                <h3 className="post-title">
                                                    <Link to={url}>{title}</Link>
                                                </h3>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className=" w-full lg:w-1/5">
                            <div className="footer-widget contact-widget services">
                                <div className="title">
                                    <h3>{FooterContactData.title}</h3>
                                </div>
                                {FooterContactData.infos.map(({ text, url }, index) => {
                                    return (
                                        <p key={index}>
                                            <a href={url}>{text}</a>
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="footer-bottom">
                <div className="thm-container clearfix">
                    <div className="float-left copy-text">
                        <p>
                            &copy; Copyright {new Date().getFullYear()} Created by{" "}
                            <a href="https://w3vitals.com" target="_blank" rel="noopener">W3Vitals</a>
                        </p>
                    </div>
                    <div className="social-box float-right">
                        {FooterBottomData.social.map(({ icon, url, text }, index) => {
                            return (
                                <a key={index} href={url} rel="noreferrer" target="_blank" className={`${icon} hvr-pulse`}>
                                    <span className="visually-hidden">{text}</span>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Footer;
